import React from "react"
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import {
  // Icon,
  Flex,
  // IconButton,
  // useColorMode,
  // Link,
  // Stack,
  // HStack,
  chakra,
  // useColorModeValue,
} from "@chakra-ui/core"
// import { DiGithubBadge } from "react-icons/di"
// import { FaMoon, FaSun } from "react-icons/fa"
// import Search from "./algolia-search"
// import Logo from "./logo"
// import StorybookIcon from "./storybook-icon"
import { Link as GatsbyLink } from "gatsby"
// import SponsorButton from "./sponsor-button"
// import MobileNav from "./mobile-nav"
import { useLocation } from "@reach/router"
import { useStaticQuery, graphql } from "gatsby"

const Brand = styled.div`
  font-size: 1.8em;
  text-transform: uppercase;
  color: #000;
  // margin-top: 1.8em;
  // margin-left: 3.6em;
`

export const NavLink = (props) => {
  const { to, ...rest } = props
  const { pathname } = useLocation()

  const group = to.split("/")[1]
  const isActive = pathname.includes(group)

  return (
    <chakra.a
      aria-current={isActive ? "page" : undefined}
      as={GatsbyLink}
      to={to}
      display="block"
      py="1"
      px="3"
      borderRadius="4px"
      transition="all 0.2s"
      // color={useColorModeValue("gray.600", "whiteAlpha.800")}
      fontWeight="normal"
      // _hover={{ bg: useColorModeValue("gray.100", "whiteAlpha.100") }}
      _activeLink={{
        fontWeight: "semibold",
        color: "teal.500",
      }}
      {...rest}
    />
  )
}

const HeaderContent = ( ) => {
  // const [, toggleMode] = useColorMode()
  // const text = useColorModeValue("dark", "light")
  // const SwitchIcon = useColorModeValue(FaMoon, FaSun)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Flex boxSize="100%" px="6" align="center" justify="space-between">
      <Flex align="center">
        <chakra.a
          as={GatsbyLink}
          to="/"
          display="block"
          aria-label="Chakra UI, Back to homepage"
        >
          {/* <Logo /> */}
          <Brand>          
            <GatsbyLink to='/'>
              {data.site.siteMetadata.title}
            </GatsbyLink>
          </Brand>
          
        </chakra.a>
        {/* <HStack
          as="nav"
          spacing="4"
          ml="24px"
          display={{ base: "none", md: "flex" }}
        >
          <NavLink to="/docs/getting-started">Docs</NavLink>
          <NavLink to="/guides">Guides</NavLink>
          <NavLink to="/team">Team</NavLink>
        </HStack> */}
      </Flex>

      <Flex
        width={["auto", "auto", "100%"]}
        maxW="60px"
        align="center"
        color="gray.500"
      >
        {/* <IconButton
          size="md"
          fontSize="lg"
          // aria-label={`Switch to ${text} mode`}
          variant="ghost"
          color="current"
          marginLeft="2"
          onClick={toggleMode}
          icon={<SwitchIcon />}
        /> */}
        {/* <MobileNav /> */}
      </Flex>
    </Flex>
  )
}

const Header = (props) => {
  // const bg = useColorModeValue("white", "gray.800")
  return (
    <chakra.header
      // pos="fixed"
      top="0"
      zIndex="1"
      // bg={bg}
      left="0"
      right="0"
      // borderBottomWidth="1px"
      width="full"
      height="4rem"
      {...props}
    >
      <HeaderContent />
    </chakra.header>
  )
}

Header.propTypes = {
	siteTitle: PropTypes.string,
}

Header.defaultProps = {
	siteTitle: ``,
}

export default Header