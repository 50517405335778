import React from 'react'
import styled from '@emotion/styled'
import {
  Box, 
  Center
} from '@chakra-ui/core'
import Header from '../header'

const Purpose = styled.div`
  font-size: 6.8em;
  text-transform: uppercase;
  margin-top: -1.1em;
  color: #000;
`

const Hero = ( ) => {
  return(
    <Box w="100%" h="100vh" bg='white'>
      <Header />
      <Center w="100%" h="100vh">
        <Purpose><span role="img" aria-label="mango">🥭</span> <span role="img" aria-label="sweet potato">🍠</span> <span role="img" aria-label="hot pepper">🌶</span></Purpose>
      </Center>
    </Box>
  )
}

export default Hero
