import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from '../components/Hero'
// import Horizon from '../components/Horizon'
// import Reveal from '../components/Reveal'
// import Appeal from '../components/Appeal'

import '../assets/styles/App.css'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
      <div className="App">       
        <Hero /> 
        {/* <Horizon /> 
        <Reveal />                 
        <Appeal /> */}
      </div>
  </Layout>
)

export default IndexPage